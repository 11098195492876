/* tslint:disable:no-console */
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {MonitoringService, StationStatus} from '../monitoring.service';
import {PeriodicRefreshService} from '../../utility/periodic-refresh.service';

@Component({
  selector: 'ae-st-health',
  templateUrl: './station-health.component.html',
  styleUrls: ['./station-health.component.scss']
})
export class StationHealthComponent implements OnInit, OnDestroy {
  private stationStream: Observable<StationStatus[]>;
  private refreshSub;
  stationList: StationStatus[];

  callStatus = 'ready';
  @Input()
  dataSets: string[] = null;

  constructor(private stationApi: MonitoringService, private refreshSvc: PeriodicRefreshService) {
  }

  ngOnInit(): void {
    this.refresh();
    this.setRefreshSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribeRefresh();
  }

  private setRefreshSubscription(): void {
    this.refreshSub = null;
    this.unsubscribeRefresh();
    this.refreshSub = this.refreshSvc.getLastRefreshed().subscribe(t => {
      this.refresh();
    });
  }

  private unsubscribeRefresh(): void {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
  }

  private formatHours(numberOfHours): string {
    const days = Math.floor(numberOfHours / 24);
    const remainder = numberOfHours % 24;
    const hours = Math.floor(remainder);
    const minutes = Math.floor(60 * (remainder - hours));
    return days + 'd ' + hours + ' h ' + minutes + ' min';
  }

  private formatDowntime(stationStatus: StationStatus): string {
    const millis = (new Date()).getTime() - Date.parse(stationStatus.created);
    const hours = millis / 3600000; // millis / 1000 / 60 / 60;
    return this.formatHours(hours);
  }

  public getUptimeDowntime(stationStatus: StationStatus): string {
    let response = 'unknown';

    if (stationStatus) {
      if (stationStatus.meta && stationStatus.meta.uptimeHours) {
        response = 'UP: ' + this.formatHours(stationStatus.meta.uptimeHours);
      } else {
        if (stationStatus.condition === 'ERROR' && stationStatus.state === 'OFFLINE') {
          response = 'DOWN: ' + this.formatDowntime(stationStatus);
        }
      }
    }
    return response;
  }

  public refresh(): void {
    this.callStatus = 'working...';
    this.stationStream = this.stationApi.getDeviceStatus(this.dataSets);

    const sub = this.stationStream.subscribe((stations) => {
      this.stationList = stations;
      this.callStatus = 'ready';
      sub.unsubscribe();
    }, (error => {
      this.callStatus = error.toString();
    }));
  }
}
