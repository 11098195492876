<div>
  <mat-accordion>
    <mat-expansion-panel id="search-panel" class="search-panel" [expanded]="isExpanded" (opened)="isExpanded = true" (closed)="isExpanded = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{searchTitle}}
        </mat-panel-title>
        <mat-panel-description>
          <span class="panel-description-item" *ngIf="includeDateSearch && startDate?.value"><b>Start:</b> {{startDate?.value | date: "yyyy-MM-dd HH:mm:ss"}}</span>
          <span class="panel-description-item" *ngIf="includeDateSearch && endDate?.value"><b>End:</b> {{endDate?.value | date: "yyyy-MM-dd HH:mm:ss"}}</span>
          <span class="panel-description-item" *ngIf="includeDataSetSearch && dataSets?.value"><b>Data Sets:</b> {{dataSets?.value}}</span>
          <span class="panel-description-item" *ngIf="includeStationSearch && stations?.value"><b>Stations:</b> {{stations?.value}}</span>
          <span class="panel-description-item" *ngIf="includeSerialNumberSearch && dataSets?.value"><b>Item ID:</b> {{serialNumberCtl?.value}}</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="searchForm">
        <div class="row">
          <div class="col-md-2">
            <mat-form-field *ngIf="includeDateSearch">
              <mat-label>Select Time Range</mat-label>
              <mat-select id="quick-search" [formControl]="searchQuickRange">
                <mat-option [value]="">Not Set</mat-option>
                <mat-option *ngFor="let range of quickSearchList"
                            [value]="range.rangeInMinutes">{{range.display}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <div *ngIf="includeDateSearch">
              <mat-form-field>
                <input matInput [ngxMatDatetimePicker]="pickerStart" placeholder="start date"
                       [formControl]="startDate"
                       [min]="" [max]="">
                <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #pickerStart [showSpinners]="true" [showSeconds]="false"
                                         [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
                                         [touchUi]="false" [color]="'primary'"
                                         [enableMeridian]="false"
                                         [disableMinute]="false" [hideTime]="false">
                </ngx-mat-datetime-picker>
              </mat-form-field>
            </div>
            <div *ngIf="includeDateSearch">
              <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="pickerEnd" placeholder="end date"
                     [formControl]="endDate"
                     [min]="" [max]="">
              <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #pickerEnd [showSpinners]="true" [showSeconds]="false"
                                       [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
                                       [touchUi]="false" [color]="'primary'"
                                       [enableMeridian]="true"
                                       [disableMinute]="false" [hideTime]="false">
              </ngx-mat-datetime-picker>
            </mat-form-field>
            </div>
          </div>
          <div *ngIf="includeDataSetSearch" class="col-md-2"  [ngClass]="{includeDateSearch:'search-filter-divider'}">
            <mat-form-field>
              <mat-label>Select DataSets</mat-label>
              <mat-select id="dataSets" [multiple]="multipleStations" [formControl]="dataSets">
                <mat-option *ngFor="let dataset of dataSetList"
                            [value]="dataset">{{dataset}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="includeStationSearch" class="col-md-2" [ngClass]="{includeDateSetSearch:'search-filter-divider'}">
            <mat-form-field>
              <mat-label>Select Stations</mat-label>
              <mat-select id="stations" [multiple]="multipleStations" [formControl]="stations">
                <mat-option *ngFor="let station of stationList"
                            [value]="station.stationId">{{station.display}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="includeStatusSearch" class="col-md-2" [ngClass]="{includeStatusSearch:'search-filter-divider'}">
            <mat-form-field>
              <mat-label>Select Status</mat-label>
              <mat-select id="status" [multiple]=false [formControl]="statusCtl">
                <mat-option *ngFor="let status of statusList"
                            [value]="status.val">{{status.display}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="includeSerialNumberSearch" class="col-md-2" [ngClass]="{includeStationSearch:'search-filter-divider'}">
            <mat-form-field>
              <mat-label>Enter Serial Number</mat-label>
              <input matInput placeholder="item ID" [formControl]="serialNumberCtl" [value]="">
            </mat-form-field>
          </div>
        </div>
        <div class="row" class="bottom-bar-right">
          <div class="col-md-12">
            <button mat-stroked-button color="warn" (click)="resetSearch();">reset</button>
            <button mat-stroked-button (click)="cancelSearch();">cancel</button>
            <button mat-stroked-button color="primary" (click)="execute()">select</button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
