import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SearchCriteria} from '../../modules/record/record-api.service';
import {StationSearchItem, StatusSearchItem} from "../../modules/core.service";

export class QuickSearchRangeItem {
  rangeInMinutes: number;
  display: string;
}

@Component({
  selector: 'ae-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {
  @Output() searchEvent: EventEmitter<SearchCriteria> = new EventEmitter<SearchCriteria>();
  @Input() searchTitle: string;
  @Input() includeDateSearch: boolean = true;
  @Input() includeStationSearch: boolean = true;
  @Input() includeSerialNumberSearch: boolean = true;
  @Input() includeDataSetSearch: boolean = true;
  @Input() includeStatusSearch: boolean = true;
  @Input() stationList: StationSearchItem[] = [];
  @Input() dataSetList: string[] = [];
  @Input() statusList: StatusSearchItem[] = [{"val": null, "display": "all"}];
  @Input() multipleStations = true;
  @Input() quickSearchList: QuickSearchRangeItem [] = [
    {rangeInMinutes: 5, display: 'Last 5 Minutes'},
    {rangeInMinutes: 10, display: 'Last 10 Minutes'},
    {rangeInMinutes: 15, display: 'Last 15 Minutes'},
    {rangeInMinutes: 20, display: 'Last 20 Minutes'},
    {rangeInMinutes: 30, display: 'Last 30 Minutes'},
    {rangeInMinutes: 60, display: 'Last Hour'},
    {rangeInMinutes: 120, display: 'Last 2 Hours'},
    {rangeInMinutes: 240, display: 'Last 4 Hours'},
    {rangeInMinutes: 360, display: 'Last 6 Hours'},
    {rangeInMinutes: 1440, display: 'Last Day'},
    {rangeInMinutes: 10080, display: 'Last Week'}
  ];
  @Input() isExpanded = true;
  @Input() set setStartDate(val: Date) {
    this.startDate.setValue(val);
  };
  @Input() set setEndDate(val: Date) {
    this.endDate.setValue(val);
  };
  @Input() set setStations(val: string[]) {
    this.stations.setValue(val);
  };
  @Input() set setDataSets(val: string[]) {
    this.dataSets.setValue(val);
  }
  @Input() set setStatus(val: string) {
    this.statusCtl.setValue(val);
  }
  @Input() set setSerialNumber(val: string) {
    this.serialNumberCtl.setValue(val);
  };


  searchForm: FormGroup;
  searchQuickRange = new FormControl('');
  startDate = new FormControl('');
  endDate = new FormControl('');
  stations = new FormControl([]);
  dataSets = new FormControl([]);
  statusCtl = new FormControl('');
  serialNumberCtl = new FormControl('');

  constructor(formBuilder: FormBuilder) {
    this.searchForm = formBuilder.group({
      quickSearch: this.searchQuickRange,
      startDate: this.startDate,
      endDate: this.endDate,
      stations: this.stations,
      dataSets: this.dataSets,
      status: this.statusCtl,
      serialNumber: this.serialNumberCtl
    });
  }

  ngOnInit(): void {
    this.searchQuickRange.valueChanges.subscribe(val => {
      if (val) {
        this.setDateControls();
      }
    });

    let currentDs = this.dataSets.value as string[];
    this.dataSets.valueChanges.subscribe((value: string[]) => {

      let newVal: string[];
      const oldAllSelected = currentDs.some(s => s === "*");
      const allIdx = value.indexOf("*");
      if (allIdx > -1 && !oldAllSelected) {
        newVal = ["*"];
      } else if (allIdx > -1 && oldAllSelected && value.length > 1) {
        newVal = value.slice(0, value.length);
        newVal.splice(allIdx, 1);
      } else {
        newVal = value.slice(0, value.length);
      }
      currentDs = newVal;
      this.dataSets.setValue(newVal, {
        onlySelf: false,
        emitEvent: false,
        emitModelToViewChange: true,
        emitViewToModelChange: true
      });

    });

    this.startDate.valueChanges.subscribe(val => {
      this.searchQuickRange.setValue(null);
    });
    this.endDate.valueChanges.subscribe(val => {
      this.searchQuickRange.setValue(null);
    });

    // init search range with first value
    this.searchQuickRange.setValue(5);
  }

  execute(): void {
    this.isExpanded = false;
    const formValue = this.searchForm.value;
    const stations = (formValue.stations) ? formValue.stations : [];
    const dataSets = (formValue.dataSets) ? formValue.dataSets : [];
    const status = (formValue.status) ? formValue.status : null;
    const searchCrit = new SearchCriteria(formValue.startDate, formValue.endDate, stations, dataSets, status, formValue.serialNumber);
    this.searchEvent.emit(searchCrit);
  }

  cancelSearch(): void {
    this.isExpanded = false;
  }

  resetSearch(): void {
    this.searchQuickRange.setValue(5);
    this.stations.setValue([]);
    this.dataSets.setValue([]);
    this.statusCtl.setValue(null);
    this.serialNumberCtl.setValue('');
  }

  private setDateControls(): void {
    if (this.searchQuickRange) {
      const endMillis = Date.now();
      const startMillis = endMillis - this.searchQuickRange.value * 60 * 1000;
      this.endDate.setValue(new Date(endMillis));
      this.startDate.setValue(new Date(startMillis));
    }
  }

}
