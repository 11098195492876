/* tslint:disable:no-console */
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {timer, zip} from 'rxjs';
import {Page, RecordApiService, SearchCriteria} from '../../modules/record/record-api.service';
import {AuthenticationRequest} from '../../modules/record/authenticationRequest';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {CoreService, LookupLists, StatusSearchItem} from "../../modules/core.service";

@Component({
  selector: 'ae-authentication-list',
  templateUrl: './authentication-list.component.html',
  styleUrls: ['./authentication-list.component.scss']
})
export class AuthenticationListComponent implements OnInit {
  currentDisplayId: string;
  currentRecord: AuthenticationRequest = null;
  authentications: AuthenticationRequest[] = null;
  lookups: LookupLists = new LookupLists([], [], new Map<string, string>(), []);
  searchCriteria: SearchCriteria;

  totalAuthenticationsLength = 0;
  pageSize = 20;
  pageSizeOptions = [5, 10, 15, 20, 25, 50];
  @ViewChild('paginator') paginator: MatPaginator;

  @ViewChild('authTop') authTopElement: ElementRef;
  @ViewChildren('authList') authListElements: QueryList<ElementRef>;

  constructor(private recordApi: RecordApiService, private coreSvc: CoreService) {
  }

  @Input() set authenticationsList(list: AuthenticationRequest[]) {
    if (list) {
      this.authentications = list;
      if (this.authentications.length > 0) {
        this.selectTopOfList();
      } else {
        this.currentDisplayId = null;
      }
    }
  }

  ngOnInit(): void {
    const statusList: StatusSearchItem[] = [
      {val: null, display: "All"},
      {val: "COMPLETED", display: "COMPLETED"},
      {val: "PENDING", display: "PENDING"}];
    this.coreSvc.getLookupLists(statusList).subscribe(lookups => {
      this.lookups = lookups;
    });
  }

  public searchHandler(searchCriteria: SearchCriteria): void {
    this.authentications = null;
    this.currentDisplayId = null;
    this.searchCriteria = searchCriteria;
    this.doSearch();
  }

  pageHandler(event: PageEvent): void {
    this.currentDisplayId = null;
    this.pageSize = this.paginator.pageSize;
    this.doSearch();
  }

  doSearch(): void {
    if (this.searchCriteria) {
      this.searchCriteria.page = new Page(this.paginator?.pageIndex || 0, this.pageSize);
      const searchStream = this.recordApi.searchAuthentications(this.searchCriteria);
      const countStream = this.recordApi.authenticationCountForFilter(this.searchCriteria);
      zip(searchStream, countStream).subscribe(([records, cnt]) => {
            this.authentications = records;
            this.totalAuthenticationsLength = cnt.count;
          }, errors => {
            this.totalAuthenticationsLength = 0;
            this.authentications = [];
            console.log('error searching authentications', errors);
          }
      );
    }
  }

  protected clearCapture(): void {
    this.currentDisplayId = null;
    this.currentRecord = null;
  }

  protected viewCaptureAndScroll(requestId: string, i: number, scroll: boolean): void {
    if (i > -1 && requestId) {
      this.currentRecord = this.authentications[i];
      this.recordApi.getAuthenticationByRequestId(requestId).subscribe(auth => this.currentRecord = auth);
    } else {
      this.currentRecord = null;
    }
    this.currentDisplayId = requestId;
    if (scroll && requestId) {
      timer(50).subscribe(() => {
        this.authListElements.toArray()[i].nativeElement.scrollIntoViewIfNeeded({behavior: 'auto'});
        timer(250).subscribe(() => {
          this.authTopElement.nativeElement.scrollIntoViewIfNeeded({behavior: 'smooth'});
        });
      });
    }
  }

  protected viewCapture(requestId: string, i: number): void {
    const doScroll: boolean = this.currentDisplayId ? false : true;
    this.viewCaptureAndScroll(requestId, i, doScroll);
  }

  private selectTopOfList(): void {
    if (!this.authentications || this.authentications.length === 0) {
      return;
    }

    const list = this.authentications;

    if (list.length > 0) {
      let index = 0;
      if (this.currentDisplayId) {
        index = list.findIndex(r => r.requestId === this.currentDisplayId);
        index = index < 0 ? 0 : index;
      }
      this.viewCaptureAndScroll(list[index]?.requestId, index, true);
    } else {
      this.clearCapture();
    }
  }
}
