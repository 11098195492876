import {NgModule} from "@angular/core";
import {MonitoringService} from "./monitoring.service";
import {BrowserModule} from "@angular/platform-browser";
import {StationHealthComponent} from "./station-health-component/station-health.component";

@NgModule({
  declarations: [StationHealthComponent],
  imports: [
    BrowserModule
  ],
  providers: [MonitoringService],
  exports: [StationHealthComponent],
  bootstrap: []
})
export class MonitoringModule {
}
