import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from "../user.service";
import {AlitheonError} from "../../base/alitheon-error";

@Component({
  selector: 'ae-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  auth: any = {
    user: null,
    password: null,
    error: null
  };

  private sub: any;

  constructor(private api: UserService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    console.log('logout prior session');
    this.api.logout();
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  public login(): void {
    this.auth.error = null;
    //console.log("login params", this.route?.snapshot?.queryParamMap);
    this.sub = this.api.login(this.auth.user, this.auth.password).subscribe(() => {
      this.auth.user = null;
      this.auth.password = null;
      this.auth.error = null;

      const redirectUrl = this.route?.snapshot?.queryParamMap?.get('redirectUrl');
      if (redirectUrl) {
        this.router.navigateByUrl(redirectUrl).catch(error => {
          this.router.navigate(['/dashboard']);
        });
      } else {
        this.router.navigate(['/dashboard']);
      }
    }, (error:AlitheonError) => {
      this.auth.error = error.userMessage;
    });
  }

}
