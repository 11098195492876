import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RecordApiService} from '../../modules/record/record-api.service';
import {PhysicalRegistration} from '../../modules/record/physicalRegistration';
import {UserService} from "../../modules/user/user.service";
import {RecordStatus} from "../../modules/record/record-common";

@Component({
  selector: 'ae-physical-reg',
  templateUrl: './physical-registration.component.html',
  styleUrls: ['./physical-registration.component.scss']
})
export class PhysicalRegistrationComponent implements OnInit {
  private registrationStream: any;
  allowedDisable = true;
  allowedEnable = true;

  public physicalId: string;
  public physicalRegistration: PhysicalRegistration;
  public previewUrl: string;

  @Input() fullView = true;
  @Input() showHeader = true;

  @Input() set canDisable(allowed: boolean) {
    this.allowedDisable = allowed;
  }

  @Input() set canEnable(allowed: boolean) {
    this.allowedEnable = allowed;
  }

  @Input() set physicalReg(reg: PhysicalRegistration) {
    if (reg) {
      this.physicalRegistration = reg;
      this.physicalId = reg.physicalIdentifier.physicalId;
      this.getCard();
    }
  }

  @Input() set cardId(physicalId: string) {
    console.log("set physicalId", physicalId);
    if (!physicalId) {
      return;
    }
    this.clear();
    if (this.physicalId != physicalId) {
      this.physicalId = physicalId;
      this.getCard();
    }
  }

  @Output() registrationChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private recordApi: RecordApiService,
              private route: ActivatedRoute,
              private userSvc: UserService) {
  }

  ngOnInit(): void {
    this.getCard();
    this.userSvc.currentUser().subscribe(user => {
      if (this.allowedDisable === true) {
        this.allowedDisable = user.roles.some(r => r.name.toLowerCase() === 'customer_admin');
      }
      if (this.allowedEnable === true) {
        this.allowedEnable = user.roles.some(r => r.name.toLowerCase() === 'customer_admin');
      }
    })
  }

  clear(): void {
    this.physicalId = null;
    this.physicalRegistration = null;
    this.previewUrl = null;
  }

  getCard(): void {
    if (!this.physicalId) {
      this.physicalId = this.route.snapshot.paramMap.get('physicalId');
    }
    if (this.registrationStream) {
      this.registrationStream.unsubscribe();
    }
    if (this.physicalRegistration) {
      this.processRegistrations();
    } else if (this.physicalId) {
      this.registrationStream = this.recordApi.getRegistration(this.physicalId).subscribe(card => {
        this.physicalRegistration = card;
        this.processRegistrations();
      });
    }
  }

  private processRegistrations(): void {
    if (this.physicalRegistration?.registration) {
      this.previewUrl = this.recordApi.computePreviewUrl(this.physicalRegistration.registration.displayImageId
          || this.physicalRegistration.registration.registration?.previewImageId);
    }
  }

  canDisablePhysicalIdentifier(): boolean {
    return this.allowedDisable && this.physicalRegistration?.physicalIdentifier?.status == RecordStatus.ENABLED;
  }

  canEnablePhysicalIdentifier(): boolean {
    return this.allowedEnable && this.physicalRegistration?.physicalIdentifier?.status == RecordStatus.DISABLED;
  }

  disable(): void {
    if (this.physicalRegistration?.physicalIdentifier) {
      this.recordApi.disableRegistration(this.physicalRegistration.physicalIdentifier.physicalId).subscribe(r => {
        this.registrationChange.emit('physical-registration-disabled');
        this.physicalRegistration = null;
        this.getCard();
      }, (error) => {
        console.log('error disabling registration', error);
      });
    }
  }

  enable(): void {
    if (this.physicalRegistration?.physicalIdentifier) {
      this.recordApi.enableRegistration(this.physicalRegistration.physicalIdentifier.physicalId).subscribe(r => {
        this.registrationChange.emit('physical-registration-enabled');
        this.physicalRegistration = null;
        this.getCard();
      }, (error) => {
        console.log('error enabling registration', error);
      });
    }
  }
}
