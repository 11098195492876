import {Injectable, NgModule} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes
} from '@angular/router';
import {DashboardComponent} from '../components/dashboard/dashboard.component';
import {AuthenticationComponent} from '../components/authentication/authentication.component';
import {RegistrationComponent} from '../components/registration/registration.component';
import {LoginComponent} from './user/login/login.component';
import {UserMgtComponent} from './user/user-mgt/user-mgt.component';
import {
  AuthenticationListComponent
} from '../components/authentication-list/authentication-list.component';
import {
  RegistrationListComponent
} from '../components/registration-list/registration-list.component';
import {
  PhysicalRegistrationComponent
} from '../components/physical-registration/physical-registration.component';
import {BaseService} from "./base/base.service";
import {AccessToken} from "./base/access-token";
import {DeDupeComponent} from "../components/de-dupe/de-dupe.component";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private baseSvc: BaseService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('auth-guard, token expired:', this.authService.isTokenExpired(), route, state);
    if (!this.baseSvc.isTokenExpired()) {
      return true;
    }
    if (route.queryParamMap.has("token")) {
      // login with supplied Bearer token and strip from url
      const bearerToken = route.queryParamMap.get("token");
      const expires = new Date(Date.now() + 1000 * 60 * 10); // artificial 10 minutes
      const token = new AccessToken(bearerToken, expires, bearerToken, expires, "Bearer");
      this.baseSvc.setToken(token);

      // strip token and route to url
      const params = {...route.queryParams};
      delete params.token;
      let queryString = "";
      for (const parm in params) {
        queryString += (queryString !== "" ? "&" : "?") + parm + "=" + params[parm];
      }
      const url = state.url.split('?')[0] + queryString;

      this.router.navigateByUrl(url).catch(error => {
        console.log("error authentication route", error);
        this.router.navigate(['/login']);
      });
      console.log("overridden access with token", bearerToken);
      return true;
    } else {

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login'], {queryParams: {redirectUrl: state.url}});
      return false;
    }
  }
}

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LoginComponent
  },
  {
    path: 'user-mgt',
    component: UserMgtComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'authentications',
    component: AuthenticationListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'authentication/:requestId',
    component: AuthenticationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'authentication/:authenticationId/de-dupe',
    component: DeDupeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'registrations',
    component: RegistrationListComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'registrations/physical/:physicalId',
    component: PhysicalRegistrationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'registrations/:registrationId',
    component: RegistrationComponent,
    canActivate: [AuthGuard]

  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}

