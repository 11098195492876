import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UUID} from 'angular2-uuid';
import {Subscription} from 'rxjs';
import {RecordApiService} from '../../modules/record/record-api.service';
import {AuthenticationRequest} from '../../modules/record/authenticationRequest';

@Component({
  selector: 'ae-auth',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  protected requestId: UUID;
  @Input() fullView = true;

  @Input() set authRequestId(value: UUID) {
    this.requestId = value;
    this.getDetail();
  }

  @Input() set authRequest(value) {
    this.authenticationRequest = value;
    this.getPreview();
  }

  private authStream: Subscription;
  public authenticationRequest: AuthenticationRequest;
  public authPreview: string;

  constructor(private recordApi: RecordApiService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (!this.authenticationRequest) {
      this.getDetail();
    }
  }

  getDetail(): void {
    if (!this.requestId) {
      this.requestId = this.route.snapshot.paramMap.get('requestId');
    }

    if (this.authStream) {
      this.authStream.unsubscribe();
    }

    this.authStream = this.recordApi.getAuthenticationByRequestId(this.requestId).subscribe(auth => {
      this.authenticationRequest = auth;
      this.authPreview = this.recordApi.computePreviewUrl(this.authenticationRequest?.displayImageId
          || this.authenticationRequest?.authentication?.previewImageId);
    });
  }

  getPreview(): void {
    if (this.authenticationRequest) {
      this.authPreview = this.recordApi.computePreviewUrl(this.authenticationRequest?.displayImageId
          || this.authenticationRequest?.authentication?.previewImageId);
    }
  }
}
