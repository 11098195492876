<div>
    <h3>Current Status</h3>
    <table class="table table-sm table-hover table-striped">
      <tbody>
      <ng-container *ngFor="let s of stationList">
        <tr>
          <td [ngClass]="s.condition">{{s.condition}}</td>
          <td>{{s.stationId}} </td>
          <td>{{getUptimeDowntime(s)}}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
</div>

