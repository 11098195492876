import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RecordApiService, SearchCriteria} from '../../modules/record/record-api.service';
import {PeriodicRefreshService} from '../../modules/utility/periodic-refresh.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {AuthenticationRequest} from '../../modules/record/authenticationRequest';
import {Router} from '@angular/router';

@Component({
  selector: 'ae-non-matches',
  templateUrl: './non-matches.component.html',
  styleUrls: ['./non-matches.component.scss']
})
export class NonMatchesComponent implements OnInit, OnDestroy {
  private refreshSub: Subscription;
  nonMatches: AuthenticationRequest[] = [];
  @Input()
  dataSets: string[] = null;

  constructor(private recordApi: RecordApiService,
              private refreshSvc: PeriodicRefreshService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.getNonMatchedAuths();
    this.refreshSub = this.refreshSvc.getLastRefreshed().subscribe(refreshed => {
      this.getNonMatchedAuths();
    });
  }

  ngOnDestroy(): void {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
      this.refreshSub = null;
    }
  }

  getNonMatchedAuths(): void {
    const now = new Date();
    const endTime = (new Date(now.getFullYear(), now.getMonth(), now.getDate())).getTime();
    const start = new Date(endTime - (this.recordApi.dayInMillis));

    // today's auths so leave off end which will also make sure we don't filter out UTC offset records
    const searchCriteria = new SearchCriteria(start, null, null, this.dataSets);
    this.recordApi.searchAuthentications(searchCriteria).subscribe(auths => {
      this.nonMatches = auths.filter(auth => auth.authentication.bestMatchId === null);
    });
  }

  gotoAuth(authenticationId: string): void {
    this.router.navigate(['/authentication', authenticationId]);
  }
}
