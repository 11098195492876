import {Injectable} from '@angular/core';
import {Observable} from "rxjs/internal/Observable";
import {zip} from "rxjs";
import {UserService} from "./user/user.service";
import {MonitoringService, StationConfig} from "./monitoring/monitoring.service";
import {RecordApiService} from "./record/record-api.service";
import {map} from "rxjs/operators";
import {UserInfo} from "./user/user-info";
import {DataSetContext} from "./record/context-models";

export class LookupLists {
  stations: StationSearchItem[] = [];
  dataSets: string[] = [];
  contextDataSetMap: Map<string, string> = new Map<string, string>();
  statusList: StatusSearchItem[] = [];

  constructor(stations: StationSearchItem[], dataSets: string[], contextDataSetMap: Map<string, string>, statusList: StatusSearchItem[]) {
    this.stations = stations;
    this.dataSets = dataSets;
    this.contextDataSetMap = contextDataSetMap;
    this.statusList = statusList
  }
}

export class StationSearchItem {
  stationId: string;
  display: string;
}

export class StatusSearchItem {
  val: string;
  display: string;
}

@Injectable()
export class CoreService {
  constructor(
      private userSvc: UserService,
      private monitorSvc: MonitoringService,
      private recordSvc: RecordApiService) {
  }

  public getLookupLists(statusList: StatusSearchItem[]): Observable<LookupLists> {
    const currentUserStream = this.userSvc.currentUser();
    const userDataSetStream = this.userSvc.listDataSets();
    const stationsStream = this.monitorSvc.getStationConfig();
    const dataSetContextStream = this.recordSvc.getDataContexts();

    const streams = zip(...[currentUserStream, userDataSetStream, stationsStream, dataSetContextStream]);

    return streams.pipe(
        map(([currentUser, dataSetList, config, dataSetContexts]) => {
          const user = currentUser as UserInfo;
          let dataSets = dataSetList as string[];
          const stations = config as StationConfig[];
          const dsContexts = dataSetContexts as DataSetContext[];

          //todo fixme: once listDataSets() call includes '*' for users with full dataSet access remove this
          if ((user?.dataSets as string[]).some(ds => ds === '*')) {
            if (!dataSets.some(ds => ds === '*')) {
              dataSets.splice(0, 0, '*');
            }
          }
          //end todo

          const stationsList = stations.map(station => {
            return {stationId: station.stationId, display: station.stationId};
          });
          const map = dsContexts
              .map(cd => {
                return [cd.contextId, cd.dataSet] as [string, string];
              })
          const contextDataSetMap = new Map(map);
          const lookups = new LookupLists(stationsList, dataSets, contextDataSetMap, statusList);
          return lookups;

        }, errors => {
          console.log('Error retrieving lookup lists', ...errors);
        })
    );
  }
}
