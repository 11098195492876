import {Injectable} from '@angular/core';
import {BehaviorSubject, NEVER, Observable, Subject} from 'rxjs';

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {UserService} from '../user/user.service';
import {BaseService} from "./base.service";
import {AlitheonError} from "./alitheon-error";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private router: Router, private baseSvc: BaseService, private userSvc: UserService) {
    // console.log('constructor router state:', this.router, this.router?.routerState);
  }

  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
  private router$;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('refresh') !== -1) {
      return next.handle(request).pipe(catchError(((err, caught) => this.handleRefreshError(err))));
    } else if (request.url.indexOf('login') !== -1) {
      return next.handle(request).pipe(catchError(((err, caught) => this.handleLoginError(err))));
    } else if (request.url.indexOf('/assets') > -1) {
      return next.handle(request);
    }

    const tokenExpired = this.baseSvc.isTokenExpired();
    this.router$ = this.router;

    if (tokenExpired) {
      //console.log(`intercept ${request.url}`, `tokenExpired:${tokenExpired}`);
      console.log("refreshing token");
      if (!this.refreshTokenInProgress) {
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);
        // console.log("calling refresh");
        return this.userSvc.refreshToken().pipe(
            catchError((err => {
              console.log("refresh-token-response-error", err);
              return this.handleError(err);
            })),
            switchMap((authResponse) => {
              console.log("refresh token response", authResponse);
              this.refreshTokenSubject.next(authResponse.refreshToken);
              this.refreshTokenInProgress = false;
              return next.handle(this.injectToken(request)).pipe(catchError(((err, caught) => this.handleError(err))));
            }),
        );
      } else {
        return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap((res) => {
              return next.handle(this.injectToken(request)).pipe(catchError(((err, caught) => {
                console.log("refresh-token-error", err);
                return this.handleError(err);
              })));
            })
        );
      }
    }

    if (!tokenExpired) {
      return next.handle(this.injectToken(request)).pipe(catchError(((err, caught) => this.handleError(err))));
    }
  }

  injectToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.baseSvc.getAccessToken()?.token;
    const authScheme = this.baseSvc.getAccessToken()?.tokenType;
    return request.clone({
      setHeaders: {
        Authorization: `${authScheme} ${token}`
      }
    });
  }

  handleRefreshError(e): Observable<never> {
    console.log("handle-refresh-error", e);
    this.router.navigate(['/login']);
    return NEVER;
  }

  handleLoginError(e): Observable<any> {
    return this.baseSvc.handleError(e);
  }

  handleError(e): Observable<never> {
    const error = AlitheonError.fromError(e);
    if (error.status === 401) {
      console.log("handle error, logout", e);
      this.router.navigate(['/login']);
      return NEVER;
    }
    return this.baseSvc.handleError(error);
  }

}
