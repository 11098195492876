import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import {BaseService} from "../base/base.service";
import {map} from "rxjs/operators";

export class StationStatus {
  id: string;
  stationId: string;
  state: string;
  condition: string;
  description: string;
  meta: any;
  created: string;
}

export class StationConfig {
  stationId: string;
  dataSet: string;
  enabled: boolean;
}

@Injectable()
export class MonitoringService {

  constructor(private baseSvc: BaseService, private http: HttpClient) {
  }

  public getStationConfig(): Observable<StationConfig[]> {
    const url = this.baseSvc.customerUrl(`conduit/customer/monitoring/device/station-config`);
    return this.http.get(url, this.baseSvc.defaultOptions())
        .pipe(map((response: any) => response.stations as StationConfig[]))
  }

  public getDeviceStatus(dataSets: string[] = null): Observable<StationStatus[]> {
    const params = {};
    if (dataSets && dataSets.length > 0) {
      params["dataSets"] = dataSets;
    }
    const url = this.baseSvc.customerUrl(`conduit/customer/monitoring/device/status/list`);
    const req: Observable<any> = this.http
        .get(url, this.baseSvc.defaultOptions({params: params}))
        .pipe(map((r) => r["conduit-device-status-list"]));

    return req;
  }
}
