<div *ngIf="authenticationId">
  <div *ngIf="authenticationRequest" class="row">
    <div class="col col-6">
      <mat-list dense="">
        <h3 matSubheader>Authentication</h3>
        <mat-card>
          <table class="table table-sm table-hover table-striped">
            <tr>
              <td rowspan="5" class="preview-wrapper">
                <img class="preview"
                     [ngClass]="{'preview-thumb': previewSize === 'thumb', 'preview-sm': previewSize === 'small'}"
                     [src]="authPreview | previewImage | async">
              </td>
              <td class="preview-label">AuthenticationId:</td>
              <td>{{authenticationRequest?.authenticationId}}</td>
            </tr>
            <tr>
              <td class="preview-label">PhysicalId:</td>
              <td>{{authenticationRequest?.physicalRegistration?.physicalIdentifier?.physicalId}}</td>
            </tr>
            <tr>
              <td class="preview-label">Created</td>
              <td>{{authenticationRequest?.created}}</td>
            </tr>
            <tr>
              <td class="preview-label">Imaged</td>
              <td>{{authenticationRequest?.authentication?.extendedData?.imageAcquisitionTime}}</td>
            </tr>
            <tr>
              <td class="preview-label">Serial Number</td>
              <td>{{authenticationRequest?.serialNumber}}</td>
            </tr>
          </table>
        </mat-card>
        <mat-divider></mat-divider>
        <h3 *ngIf="this.authenticationRequest?.physicalRegistration" matSubheader>Matching
          Registration</h3>
        <ae-physical-reg *ngIf="this.authenticationRequest?.matchSerialNumber"
                         [fullView]="false"
                         [physicalReg]="authenticationRequest?.physicalRegistration"
                         (registrationChange)="matchRegistrationChangeHandler($event)"
        ></ae-physical-reg>
        <mat-card *ngIf="this.authenticationRequest?.physicalRegistration == null">
          No Match
        </mat-card>
      </mat-list>
    </div>
    <div class="col col-6">
      <mat-list dense="true">
        <h3 matSubheader>Additional Registrations for S/N
          [{{this.authenticationRequest?.serialNumber}}]</h3>
        <mat-card *ngFor="let reg of duplicateRegistrations ">
          <ae-physical-reg
              [fullView]="false"
              [physicalReg]="reg"
              (registrationChange)="dupeRegistrationChangeHandler($event)"
          ></ae-physical-reg>
        </mat-card>
        <mat-card *ngIf="!hasDupRegistrations()">
          No `ENABLED` Duplicates Found. You can close the browser now.
        </mat-card>
      </mat-list>
    </div>
  </div>
</div>
<div *ngIf="!authenticationId">
  <h2>!!! No Authentication ID Provided !!!</h2>
</div>
