<div class="divider">&nbsp;</div>
<div class="container-fluid record-header">
  <mat-card>
    <h2 mat-card-title>Authentication</h2>
    <h3 mat-card-subtitle>Serial Number: {{ authenticationRequest?.serialNumber || 'N/A' }}</h3>
  </mat-card>
</div>
<div class="container-fluid">
  <div class="row row-padded">
    <div class="col-md-3">
      <mat-card *ngIf="authenticationRequest" [ngClass]="{'record-view-full': fullView === true}"
                class="record-card">
        <div>
          <table class="table table-sm table-hover table-striped">
            <thead>
            <tr style="border:none">
              <th colspan="2" class="record-title">Authentication</th>
            </tr>
            </thead>
            <tbody>
            <tr class="preview-wrapper-row">
              <td *ngIf="authenticationRequest.authentication" class="preview-wrapper" colspan="2">
                <img alt="preview" class="preview preview-sm"
                     [src]="authPreview | previewImage | async">
              </td>
              <td *ngIf="!authenticationRequest.authentication" class="preview-wrapper" colspan="2">
                <mat-icon>image_not_supported</mat-icon>
              </td>
            </tr>
            <tr>
              <td class="preview-label">AuthenticationId:</td>
              <td>{{authenticationRequest.authenticationId}}</td>
            </tr>
            <tr>
              <td class="preview-label">Authentication ItemId:</td>
              <td>{{authenticationRequest?.serialNumber}}</td>
            </tr>
            <tr>
              <td class="preview-label">Context:</td>
              <td>{{authenticationRequest?.contextId}}</td>
            </tr>
            <tr>
              <td class="preview-label">Station:</td>
              <td>{{authenticationRequest.stationId}}</td>
            </tr>
            <tr>
              <td class="preview-label">Authentication Status</td>
              <td>{{authenticationRequest.status}}</td>
            </tr>
            <tr>
              <td class="preview-label">Created</td>
              <td>{{authenticationRequest.created | date: 'yyyy-MM-dd hh:mm:ss'}}</td>
            </tr>
            <tr>
              <td class="preview-label">Auth Data</td>
              <td>
                <pre>{{authenticationRequest.data | json}}</pre>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
    </div>
    <div class="col-md-9">
      <div class="no-match" *ngIf="!(authenticationRequest?.physicalRegistration?.physicalIdentifier?.physicalId)">
        <mat-card>No Matching Item</mat-card>
      </div>
      <ae-physical-reg *ngIf="authenticationRequest?.physicalRegistration?.physicalIdentifier?.physicalId"
                  [fullView]="false"
                  [showHeader]="false"
                  [cardId]="authenticationRequest?.physicalRegistration.physicalIdentifier.physicalId"
                  [canDisable]="false"
                  [canEnable]="false">
      </ae-physical-reg>
    </div>
  </div>
</div>
