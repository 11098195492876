import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RecordApiService} from '../../modules/record/record-api.service';
import {PeriodicRefreshService} from '../../modules/utility/periodic-refresh.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {zip} from 'rxjs';

@Component({
  selector: 'ae-fp-chart',
  templateUrl: './featureprint-chart.component.html',
  styleUrls: ['./featureprint-chart.component.scss']
})
export class FeatureprintChartComponent implements OnInit, OnDestroy {
  private refreshSub: Subscription;
  private dataSets: string[];

  @Input() set setDataSets(val: string[]) {
    if (val && val != this.dataSets) {
      this.dataSets = val.slice();
      this.refreshData();
    }
  }

  private streamSub: Subscription;
  chartData = [];

  view: any[] = [1000, 500];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Day';
  showYAxisLabel = true;
  yAxisLabel = 'Counts';
  legendTitle = null;

  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#AAAAAA']
  };

  onSelect(data): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  constructor(private recordApi: RecordApiService,
              private refreshSvc: PeriodicRefreshService) {
  }

  ngOnInit(): void {
    this.refreshData();
    this.refreshSub = this.refreshSvc.getLastRefreshed().subscribe(refreshed => {
      this.refreshData();
    });
  }

  ngOnDestroy(): void {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
      this.refreshSub = null;
    }
    if (this.streamSub) {
      this.streamSub.unsubscribe();
    }
  }

  private refreshData() {
    if (this.streamSub) {
      this.streamSub.unsubscribe();
    }
    this.getChartData();
  }

  private seedChartRange(endDate: Date = new Date()): any[] {
    const data = [];
    const end = endDate.getTime();
    for (let day = 9; day > -1; day--) {
      const dateMillis = end - (day * this.recordApi.dayInMillis);
      const dt = (new Date(dateMillis)).toISOString();
      const dateName = (dt).split('T')[0]; // ex "2020-10-01T13:00:00.123" -> "2020-10-01"
      data.push({
        name: dateName,
        series: [
          {name: 'Registrations', value: 0},
          {name: 'Authentications', value: 0}
        ]
      });
    }

    return data;
  }

  private getChartData(): void {
    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - (10 * this.recordApi.dayInMillis));
    const data = this.seedChartRange(endDate);

    const regStream = this.recordApi.registrationCountsByDay(this.dataSets, startDate, endDate);
    const authStream = this.recordApi.authenticationCountsByDay(this.dataSets, startDate, endDate);
    const stream = zip(regStream, authStream);
    this.streamSub = stream.subscribe(([regs, auths]) => {
      regs.forEach(regCnt => {
        const idx = data.findIndex(d => d.name === regCnt.day);
        // registrations are first item in series
        if (idx > -1) {
          data[idx].series[0].value = regCnt.count;
        }
      });
      auths.forEach(authCnt => {
        const idx = data.findIndex(d => d.name === authCnt.day);
        // authentications are second item is series
        if (idx > -1) {
          data[idx].series[1].value = authCnt.count;
        }
      });

      this.chartData = data;

    }, ([regs, auths]) => {
      console.log('error getting chart counts', regs, auths);
    });
  }

}
