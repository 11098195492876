import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UUID} from 'angular2-uuid';
import {Subscription} from "rxjs";
import {PhysicalRegistration} from "../../modules/record/physicalRegistration";
import {RecordApiService, SearchCriteria} from "../../modules/record/record-api.service";
import {AuthenticationRequest} from "../../modules/record/authenticationRequest";
import {RecordStatus} from "../../modules/record/record-common";

@Component({
  selector: 'ae-de-dupe',
  templateUrl: './de-dupe.component.html',
  styleUrls: ['./de-dupe.component.scss']
})
export class DeDupeComponent implements OnInit {
  authenticationId: UUID;
  authPreview: string;
  previewSize = "small";

  private authenticationSubscription: Subscription;
  public authenticationRequest: AuthenticationRequest;
  public duplicateRegistrations: PhysicalRegistration[] = [];

  constructor(private recordApi: RecordApiService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.authenticationId = this.route.snapshot.paramMap.get('authenticationId');
    this.getAuthentication();
  }

  getAuthentication(): void {
    if (this.authenticationSubscription) {
      this.authenticationSubscription.unsubscribe();
    }

    if (this.authenticationId) {
      this.authenticationSubscription = this.recordApi.getAuthentication(this.authenticationId).subscribe(auth => {
        this.authenticationRequest = auth;
        this.authPreview = this.recordApi.computePreviewUrl(auth.authentication.previewImageId);
        this.getDupRegistrations();
      });
    }
  }

  getDupRegistrations(): void {
    if (this.authenticationRequest) {
      const matchPhysicalId = this.authenticationRequest.physicalRegistration?.physicalIdentifier?.physicalId;
      const search: SearchCriteria = {serialNumber: this.authenticationRequest.serialNumber, status: RecordStatus.ENABLED} as SearchCriteria;
      this.recordApi.searchRegistrations(search).subscribe(regs => {
        this.duplicateRegistrations = regs.filter(r => r.physicalIdentifier.physicalId !== matchPhysicalId);
      });
    }
  }

  hasDupRegistrations(): boolean {
    let hasDupes = false;
    if (this.authenticationRequest) {
      const matchEnabled = this.authenticationRequest.physicalRegistration?.physicalIdentifier?.status === RecordStatus.ENABLED;
      //console.log("dupRegs", this.duplicateRegistrations?.length, "matchEnabled", matchEnabled);
      if (this.duplicateRegistrations?.length > 1 || (this.duplicateRegistrations?.length === 1 && matchEnabled)) {
        hasDupes = true;
      }
    }
    return hasDupes;
  }

  getRegistrationPreview(physicalReg: PhysicalRegistration): string {
    let imageId = physicalReg?.registration?.displayImageId || physicalReg?.registration?.registration?.previewImageId;
    return this.recordApi.computePreviewUrl(imageId);
  }

  matchRegistrationChangeHandler(registrationStatus: string): void {
    this.getAuthentication();
  }

  dupeRegistrationChangeHandler(registrationStatus: string): void {
    this.getDupRegistrations();
  }
}
