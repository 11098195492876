<div *ngIf="showHeader && physicalId" class="container-fluid record-header">
  <mat-card>
    <h3 mat-card-title>Registration: {{ physicalRegistration?.physicalIdentifier?.serialNumber }}</h3>
  </mat-card>
</div>
<div class="container-fluid" *ngIf="physicalId">
  <div class="row row-padded">
    <div class="col-md-6">
      <ae-reg [fullView]="false" [registrationRecord]="physicalRegistration?.registration"></ae-reg>
    </div>
    <div class="col-md-6">
      <mat-card class="record-card">
        <table class="table table-sm table-hover table-striped">
          <thead>
          <tr style="border:none">
            <th colspan="2" class="record-title">Details</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Serial Number:</td>
            <td class="detail-label">{{ physicalRegistration?.physicalIdentifier?.serialNumber }}</td>
          </tr>
          <tr>
            <td>Serial Number State:</td>
            <td class="detail-label">{{ physicalRegistration?.physicalIdentifier?.serialNumberState }}</td>
          </tr>
          <tr>
            <td>Customer ID:</td>
            <td class="detail-label">{{ physicalRegistration?.physicalIdentifier?.customerId }}</td>
          </tr>
          <tr>
            <td>Customer Name:</td>
            <td class="detail-label">{{ physicalRegistration?.physicalIdentifier?.customerName }}</td>
          </tr>
          <tr>
            <td class="preview-label">Physical Id:</td>
            <td>{{physicalRegistration?.physicalIdentifier?.physicalId}}</td>
          </tr>
          <tr>
            <td>Data:</td>
            <td class="detail-label">
              {{ physicalRegistration?.physicalIdentifier?.data | json }}
            </td>
          </tr>
          <tr *ngIf="canDisablePhysicalIdentifier() || canEnablePhysicalIdentifier()">
            <td colspan="2" *ngIf="canDisablePhysicalIdentifier()"><button (click)="disable()" mat-stroked-button color="warn">Disable</button></td>
            <td colspan="2" *ngIf="canEnablePhysicalIdentifier()"><button (click)="enable()" mat-stroked-button color="warn">Enable</button></td>
          </tr>
          </tbody>
        </table>
      </mat-card>
    </div>
  </div>
</div>
<div class=container-fluid *ngIf="!physicalId"><mat-card>Missing</mat-card></div>
