<div>
  <h3>FeaturePrint Stats</h3>
  <table class="table table-sm table-hover table-striped">
    <tbody>
    <tr>
      <td class="preview-label">Registrations Today:</td>
      <td>{{dailyRegs}}</td>
    </tr>
    <tr>
      <td class="preview-label">Total Registrations:</td>
      <td>{{totalRegs}}</td>
    </tr>
    <tr>
      <td class="preview-label">Authentications Today:</td>
      <td>{{dailyAuths}}</td>
    </tr>
    <tr>
      <td class="preview-label">Total Authentications:</td>
      <td>{{totalAuths}}</td>
    </tr>
    </tbody>
  </table>
</div>


