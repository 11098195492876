<div>
  <div class="search-filter">
    <ae-search-filter class="search-filter"
                      [searchTitle]="'Registrations Search'"
                      [stationList]="lookups.stations"
                      [dataSetList]="lookups.dataSets"
                      [statusList]="lookups.statusList"
                      [multipleStations]="true"
                      (searchEvent)="searchHandler($event)">
    </ae-search-filter>
  </div>

  <div #regTop>
    <mat-card *ngIf="registrations?.length > 0">
      <div class="header-menu">
        <h3 class="header-menu-title">Registrations count: {{totalRegistrationLength || 0}}</h3>
        <button *ngIf="currentCardId" mat-button (click)="clearCurrent();">
          clear selection
        </button>
      </div>

      <div class="scroll-pane scroll-pane-all"
           [ngClass]="{'scroll-pane-detail': currentCardId}">
        <table class="table table-sm table-hover table-striped">
          <thead>
          <tr>
            <th>Serial Number</th>
            <th>Customer</th>
            <th>S/N State</th>
            <th>Context</th>
            <th>Station</th>
            <th>DataSet</th>
            <th>Status</th>
            <th>Created</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let r of registrations; index as i" #regList
              [ngClass]="{'selected': currentCardId == r?.physicalIdentifier?.physicalId}"
              class="link-active"
              (click)="currentCardId == r?.physicalIdentifier?.physicalId ? clearCurrent() : viewCapture(r.physicalIdentifier?.physicalId, i)"
          >
            <td>
              <a class="link-active" matTooltip="click to open registration in new tab"
                 target="_blank"
                 [routerLink]="['/registrations/physical', r?.physicalIdentifier?.physicalId]"> {{r?.physicalIdentifier?.serialNumber}} </a>
            </td>
            <td matTooltip="click row for details" *ngIf="r.physicalIdentifier?.customerName">{{r.physicalIdentifier?.customerId}} ({{r.physicalIdentifier?.customerName}}) </td>
            <td matTooltip="click row for details" *ngIf="!r.physicalIdentifier?.customerName">{{r.physicalIdentifier?.customerId}}</td>
            <td matTooltip="click row for details">{{r.physicalIdentifier?.serialNumberState}}</td>
            <td matTooltip="click row for details">{{r.registration?.contextId | ellipsis:8}}</td>
            <td matTooltip="click row for details">{{r.registration?.stationId}}</td>
            <td matTooltip="click row for details">{{getDataSetName(
                r.registration?.contextId)}}</td>
            <td matTooltip="click row for details">{{r.physicalIdentifier?.status}}</td>
            <td matTooltip="click row for details">{{r.physicalIdentifier?.created | date: "yyyy-MM-dd HH:mm:ss" }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </mat-card>
    <mat-paginator #paginator
                   [length]="totalRegistrationLength || 0"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   [hidden]="totalRegistrationLength < 1"
                   (page)="pageHandler($event)"
    ></mat-paginator>
    <mat-card *ngIf="registrations?.length === 0">
      No Matching Records
    </mat-card>

    <ae-physical-reg *ngIf="currentCardId"
                     [cardId]="currentCardId"
                     [canDisable]="true"
                     [canEnable]="true"
                     [fullView]="false"
                     (registrationChange)="registrationChangeHandler($event)">
    </ae-physical-reg>
  </div>
</div>
