import {NgModule} from "@angular/core";
import {PreviewImagePipe} from "./preview-image";
import {UtcPipe} from "./utc";
import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from "../app-routing.module";
import {PeriodicRefreshService} from "./periodic-refresh.service";
import {EllipsisPipe} from "./ellipsis.pipe";


@NgModule({
  declarations: [
    PreviewImagePipe,
    UtcPipe,
    EllipsisPipe
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    UtcPipe,
    PreviewImagePipe,
    PeriodicRefreshService,
    EllipsisPipe
  ],
  exports: [
    UtcPipe,
    PreviewImagePipe,
    EllipsisPipe
  ],
  bootstrap: []
})
export class UtilityModule {
}
