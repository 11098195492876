/* tslint:disable:no-console */
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {timer, zip} from 'rxjs';
import {PhysicalRegistration} from '../../modules/record/physicalRegistration';
import {Page, RecordApiService, SearchCriteria} from '../../modules/record/record-api.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MonitoringService} from "../../modules/monitoring/monitoring.service";
import {UserService} from "../../modules/user/user.service";
import {CoreService, LookupLists, StatusSearchItem} from "../../modules/core.service";

@Component({
  selector: 'ae-registration-list',
  templateUrl: './registration-list.component.html',
  styleUrls: ['./registration-list.component.scss']
})
export class RegistrationListComponent implements OnInit {
  lookups: LookupLists = new LookupLists([], [], new Map<string, string>(), []);
  currentCardId: any;

  searchCriteria: SearchCriteria;
  registrations: PhysicalRegistration[] = null;
  totalRegistrationLength = 0;
  pageIndex = 0;
  pageSize = 20;
  pageSizeOptions = [5, 10, 15, 20, 25, 50];
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('regTop') regTopElement: ElementRef;
  @ViewChildren('regList') regListElements: QueryList<ElementRef>;

  constructor(private recordApi: RecordApiService, private monitorSvc: MonitoringService, private userSvc: UserService, private coreSvc: CoreService) {
  }

  ngOnInit(): void {
    const statusList: StatusSearchItem[] = [
        {val: null, display: "All"},
        {val: "ENABLED",display: "ENABLED"},
        {val: "DISABLED", display: "DISABLED"},
        {val: "PENDING", display: "PENDING"}
    ];
    this.coreSvc.getLookupLists(statusList).subscribe(lookups => {
      this.lookups = lookups;
    });
  }

  public searchHandler(searchCriteria: SearchCriteria): void {
    this.registrations = null;
    this.currentCardId = null;
    this.searchCriteria = searchCriteria;
    this.doSearch();
  }

  doSearch(): void {
    if (this.searchCriteria) {
      this.searchCriteria.page = new Page(this.paginator?.pageIndex || 0, this.pageSize);
      const searchStream = this.recordApi.searchRegistrations(this.searchCriteria);
      const countStream = this.recordApi.registrationCountForFilter(this.searchCriteria);
      zip(searchStream, countStream).subscribe(([records, cnt]) => {
            this.registrations = records;
            this.totalRegistrationLength = cnt.count;
          }, errors => {
            this.totalRegistrationLength = 0;
            this.registrations = [];
            console.log('error searching registrations', errors);
          }
      );
    }
  }

  pageHandler(event: PageEvent): void {
    this.currentCardId = null;
    this.pageSize = this.paginator.pageSize;
    this.doSearch();
  }

  registrationChangeHandler(event: string) {
    this.doSearch();
  }

  @Input('registrations') set registrationList(list: PhysicalRegistration[]) {
    if (list) {
      this.registrations = list;
      if (this.registrations.length > 0) {
        this.selectTopOfList();
      } else {
        this.currentCardId = null;
      }
    }
  }

  protected clearCurrent(): void {
    this.currentCardId = null;
  }

  protected viewCaptureAndScroll(id: string, i: number, scroll: boolean): void {
    this.currentCardId = id;
    if (scroll && id) {
      timer(50).subscribe(() => {
        this.regListElements.toArray()[i].nativeElement.scrollIntoViewIfNeeded({behavior: 'auto'});
        timer(250).subscribe(() => {
          this.regTopElement.nativeElement.scrollIntoViewIfNeeded({behavior: 'smooth'});
        });
      });
    }
  }

  protected viewCapture(id: string, i: number): void {
    const doScroll: boolean = this.currentCardId ? false : true;
    this.viewCaptureAndScroll(id, i, doScroll);
  }

  protected getDataSetName(contextId: string) {
    if (!contextId) {
      return null;
    }
    const ds = this.lookups.contextDataSetMap.get(contextId);
    return ds;
  }

  private selectTopOfList(): void {
    if (!this.registrations || this.registrations.length === 0) {
      return;
    }

    const list = this.registrations;

    if (list.length > 0) {
      let index = 0;
      if (this.currentCardId) {
        index = list.findIndex(r => r.physicalIdentifier?.physicalId === this.currentCardId);
        index = index < 0 ? 0 : index;
      }
      this.viewCaptureAndScroll(list[index]?.physicalIdentifier?.physicalId, index, true);
    } else {
      this.clearCurrent();
    }
  }
}
